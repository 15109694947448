export default {
  functional: {
    id: 'cookies_settings_functional_label',
    defaultMessage: 'Functional cookies',
  },
  functionalDescription: {
    id: 'cookies_settings_functional_description',
    defaultMessage:
      'These cookies are essential for browsing the site. They therefore cannot be deactivated.',
  },
  functionalList: {
    id: 'cookies_settings_functional_list',
    defaultMessage: 'List of functional cookies',
  },

  userExperience: {
    id: 'cookies_settings_user_experience_label',
    defaultMessage: 'Personalization cookies',
  },
  userExperienceDescription: {
    id: 'cookies_settings_user_experience_description',
    defaultMessage:
      'Do you accept the placement and reading of cookies so that Back Market and our partners personalize your experience? If you choose "yes", you choose an optimal quality of navigation, making your experience more pleasant and adapted to your device.',
  },
  userExperienceList: {
    id: 'cookies_settings_user_experience_list',
    defaultMessage: 'List of user experience cookies',
  },

  analytics: {
    id: 'cookies_settings_analytics_label',
    defaultMessage: 'Analytical cookies',
  },
  analyticsDescription: {
    id: 'cookies_settings_analytics_description',
    defaultMessage:
      'Do you accept the placement and reading of cookies in order to analyze your browsing and allow us with our partners to measure the audience of our site? NB: Some audience measurement cookies do not require consent and therefore cannot be disabled.',
  },
  analyticsList: {
    id: 'cookies_settings_analytics_list',
    defaultMessage: 'List of analytics cookies',
  },

  advertising: {
    id: 'cookies_settings_advertising_label',
    defaultMessage: 'Advertising cookies',
  },
  advertisingDescription: {
    id: 'cookies_settings_advertising_description',
    defaultMessage:
      'Do you accept the placement and reading of cookies in order to analyze your areas of interest in order to offer you personalized advertising with our partners? If you choose "yes", you will receive advertisements tailored to your interests.',
  },
  advertisingList: {
    id: 'cookies_settings_advertising_list',
    defaultMessage: 'List of advertising cookies',
  },

  legalText: {
    id: 'cookies_settings_legal_text',
    defaultMessage:
      'To find out more about the cookies used by our partners and the data they collect, please contact them directly or consult their privacy policy.',
  },
  genericLink: {
    id: 'cookies_settings_generic_link',
    defaultMessage:
      'To find out more about cookies and the use of your data for targeted advertising/sharing, please read our {cookiesLink, html} and our {dataProtectionLink, html}.',
  },
  cookiesText: {
    id: 'cookies_settings_legal_link',
    defaultMessage: 'cookie policy',
  },
  dataProtectionText: {
    id: 'cookies_settings_data_protection_link',
    defaultMessage: 'privacy policy',
  },
}
