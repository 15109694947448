
import { RevToast } from '@ds/components/Toast'

import { TOAST_COOKIES_SETTINGS } from '@config/constants/toasts'

import translations from './Notification.translations'
import { TOAST_TYPE } from './constants'

export default {
  components: {
    RevToast,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: TOAST_TYPE.SUCCESS,
    },
  },
  computed: {
    TOAST_COOKIES_SETTINGS: () => TOAST_COOKIES_SETTINGS,
    translations: () => translations,
    toast() {
      switch (this.type) {
        case TOAST_TYPE.SUCCESS:
          return {
            title: this.$t(translations.toastSuccessTitle),
            message: this.$t(translations.toastSuccessMessage),
            icon: this.$static('/img/toast/rabbit.svg'),
            variant: 'success',
          }
        case TOAST_TYPE.ERROR:
          return {
            title: this.$t(translations.toastErrorTitle),
            message: this.$t(translations.toastErrorMessage),
            icon: this.$static('/img/toast/storm.svg'),
            variant: 'error',
          }
        default:
          return {
            title: '',
            message: '',
            icon: '',
            variant: '',
          }
      }
    },
  },
}
