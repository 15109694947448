
import { RevButton } from '@ds/components/Button'
import { FORM_VALID, RevForm } from '@ds/components/Form'
import { RevLink } from '@ds/components/Link'
import { RevToggle } from '@ds/components/Toggle'
import { mapGetters } from 'vuex'

import { DEFAULT_STATE, TYPES } from '@config/constants'
import FormattedMessage from '@i18n/components/FormattedMessage'
import logger from '@logger'
import { ROUTES } from '@router'
import { trackClick, trackFormSubmit } from '@tracking/events'

import { COOKIES_TRACKING_NAME, LEGAL_PAGE_NAME } from '../../constants'

import translations from './CookiesSettings.translations'
import ToggleText from './ToggleText'

export default {
  components: {
    FormattedMessage,
    RevLink,
    RevButton,
    RevForm,
    RevToggle,
    ToggleText,
  },
  props: {
    buttonLabel: {
      type: String,
      required: true,
    },
    shrinkButtonDesktop: {
      type: Boolean,
      default: false,
    },
    trackingZone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      initialValues: DEFAULT_STATE,
    }
  },
  computed: {
    ...mapGetters({
      cookies: 'gdpr/cookies/all',
    }),
    translations: () => translations,
    ROUTES: () => ROUTES,
    LEGAL_PAGE_NAME: () => LEGAL_PAGE_NAME,
    TYPES: () => TYPES,
  },
  mounted() {
    this.initialValues = this.cookies
  },
  methods: {
    // there is nothing to validate
    validate: () => FORM_VALID,

    handleToggle(type) {
      trackClick({
        zone: this.trackingZone,
        name: `${COOKIES_TRACKING_NAME.TOGGLE}_${type}`,
      })
    },

    async save(values) {
      this.loading = true

      trackFormSubmit({
        zone: this.trackingZone,
        name: COOKIES_TRACKING_NAME.SAVE,
      })

      try {
        await this.$store.dispatch('gdpr/cookies/save', values)
        this.$emit('success')
      } catch (error) {
        logger.error('[CookiesSettings] Error saving the user choice', {
          error,
        })
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
  },
}
